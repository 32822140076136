
import { FormPage } from '@/mixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import StateEditor from '@/components/collections/zones/StateEditor.vue'
import AdditionalZoneEditor from '@/components/collections/business/AdditionalZoneEditor.vue'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import {
  BusinessBranchModel,
  BusinessBranchSocialLink,
  SocialLinkType
} from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import SignupCheckoutRequest, { SignupPackage } from 'tradingmate-modules/src/models/api/signup/SignupCheckoutRequest'
import WebsiteDomainChecker from '@/components/collections/my-website/WebsiteDomainChecker.vue'
import BusinessBranchMembershipType
  from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchMembershipType'
import Utils from 'tradingmate-modules/src/Utils'

import PackageCheckoutQueries from '../../../../../tradingmate-modules/src/models/common/PackageCheckoutQueries'

@Component({
  components: {
    WebsiteDomainChecker,
    AdditionalZoneEditor,
    StateEditor
  }
})
export default class PackageCheckout extends Mixins(FormPage) {
  private utils = Utils;
  // Website Stuff
  private hasWebsiteLink = false
  private websiteDomainValid = false
  private websiteDomain = ''

  // private localSelected = true
  private statewideSelected = false

  private photographSelected: boolean | null = false
  private websiteSelected: boolean | null = false
  private promoPackSelected: boolean | null = false
  // private allAussieExtrasSelected: boolean | null = null

  private nationalSelected: boolean | null = false
  // private additionalStates: DropdownListItem[] = []
  private additionalZones: ZoneModel[] = []
  private mainStateId = ''

  private seeMore = false

  // Pre-filled stuff
  private wantsExpress = false // passed in query
  private expressSelected = false // checkout is ticked

  @Prop({ required: true })
  private readonly business!: BusinessBranchModel
  // private readonly business: BusinessBranchModel = PlaceholderBusiness

  mounted (): void {
    // Check if business already has a website link
    const socialLinks: BusinessBranchSocialLink[] = this.business.SocialLinks
    const websiteLink = socialLinks.some(x => x.LinkType === SocialLinkType.Website)
    if (websiteLink) {
      this.hasWebsiteLink = true
      this.websiteSelected = false
    }

    this.photographSelected = !!this.$route.query.photo

    this.wantsExpress = !!this.$route.query.express
    this.expressSelected = this.wantsExpress

    const packagePreSelection: PackageCheckoutQueries = this.$route.query.p as PackageCheckoutQueries

    switch (packagePreSelection) {
      case PackageCheckoutQueries.B2b: {
        this.onLocalClick()
        break
      }
      case PackageCheckoutQueries.StateWide: {
        if (this.business.StateId) { this.stateSelected(this.business.StateId.toString()) }
        break
      }
      case PackageCheckoutQueries.Website: {
        if (this.business.StateId) { this.stateSelected(this.business.StateId.toString()) }
        this.suggestDomainName()
        this.onWebsiteSelected()
        break
      }
      case PackageCheckoutQueries.Promo: {
        if (this.business.StateId) { this.stateSelected(this.business.StateId.toString()) }
        this.suggestDomainName()
        this.onPromoPackClick()
        break
      }
      case PackageCheckoutQueries.AllAussieExtra: {
        this.suggestDomainName()
        this.onNationalClick()
        break
      }
    }

    // if they have already selected a package then select that package.
    // automatically select state if there is state package and then
  }

  suggestDomainName (): void {
    this.websiteDomain = this.business.TradingName.replace(' ', '')
  }

  negativeOptionScheme (state: boolean | null): 'primary' | 'subtle' | 'tertiary' {
    if (state === null) {
      return 'primary'
    }
    return state ? 'subtle' : 'tertiary'
  }

  // PRICING CALCULATIONS
  get subscriptionPrice (): number {
    if (this.expressSelected) return 0
    if (this.websiteSelected || this.promoPackSelected || this.nationalSelected)
    {
      // No charge for these packages
      return 0
    }
    return 99
  }

  get expressPricing (): number {
    if (!this.expressSelected) return 0
    if (this.websiteSelected || this.promoPackSelected || this.nationalSelected)
    {
      // No charge for these packages
      return 0
    }
    return 66
  }

  get regionPricing (): number {
    let runningTotal = 0

    if (this.promoPackSelected || this.nationalSelected)
    {
      return 0
    }

    if (!this.expressSelected) {
      if (this.localSelected || this.statewideSelected) {
        runningTotal += 99
      }
    }
    else {
      runningTotal += 66
    }

    if (this.mainStateId)
    {
      const price = this.utils.getState(this.mainStateId.toString())?.Price ?? 0
      runningTotal += price
    }

    // TODO: remove regions overlapping with selected states.

    runningTotal += (this.additionalZones.length * 22)
    return runningTotal
  }

  get isAdditionalZoneOrStateSelected (): boolean {
    console.log(this.additionalZones.length)
    if (this.mainStateId !== '' || this.additionalZones.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  get isMembershipIncluded (): boolean {
    if (this.websiteSelected || this.promoPackSelected || this.nationalSelected) {
      return true
    }
    else {
      return false
    }
  }

  get isWebsiteIncluded (): boolean {
    if (this.promoPackSelected || this.nationalSelected) {
      return true
    }
    else {
      return false
    }
  }

  get photographPricing (): number {
    return this.photographSelected ? 220 : 0
  }

  get websitePrice (): number {
    if (this.nationalSelected || this.promoPackSelected) return 0
    if (this.websiteSelected) {
      return 550
    }
    return 0
  }

  get promoPackPrice (): number {
    if (!this.promoPackSelected) return 0
    if (this.mainStateId)
    {
      return 1320
    }
    return 1320
  }

  get allAussiesExtraPrice (): number {
    if (!this.nationalSelected) return 0
    return 2310
  }

  // What you will actually be paying
  get totalPrice (): number {
    let runningTotal = 0

    // Base membership
    runningTotal += this.subscriptionPrice

    // Express
    runningTotal += this.expressPricing

    // Zone/state
    if (this.statewideSelected && this.mainStateId && !this.promoPackSelected && !this.websiteSelected) {
      runningTotal += this.utils.getState(this.mainStateId.toString())?.Price ?? 0
    }

    // Extra regions
    runningTotal += (this.additionalZones.length * 22)

    // Photo
    runningTotal += this.photographPricing

    // Website
    runningTotal += this.websitePrice

    // Promo pack
    if (this.promoPackSelected) { runningTotal += 1320 }

    // National/All aussie extras
    if (this.nationalSelected) { runningTotal += 2310 }

    return runningTotal
  }

  // RAW VALUES
  get allAussieExtrasValue (): number {
    if (this.nationalSelected) return 3206
    return 0
  }

  get promoPackValue (): number {
    if (this.promoPackSelected) return 1628
    return 0
  }

  get isRenewing (): boolean {
    if (this.localSelected && this.business.MembershipType === BusinessBranchMembershipType.B2B) {
      return true
    } else if (this.statewideSelected && this.business.MembershipType === BusinessBranchMembershipType.Statewide) {
      return true
    } else if (this.websiteSelected && this.business.MembershipType === BusinessBranchMembershipType.Website) {
      return true
    } else if (this.promoPackSelected && this.business.MembershipType === BusinessBranchMembershipType.PromoPack) {
      return true
    } else if (this.nationalSelected && this.business.MembershipType === BusinessBranchMembershipType.National) {
      return true
    }
    return false
  }

  // Discount is not used anywhere
  get discountPricing (): number {
    if (this.websiteSelected || this.promoPackSelected || this.nationalSelected) {
      return 0
    }
    if (this.business.NonProfitCharity && this.isRenewing)
    {
      return 75
    } else if (this.business.NonProfitCharity)
    {
      return 50
    } else if (this.business.MembershipType === BusinessBranchMembershipType.B2B && this.localSelected)
    {
      return 50
    } else if (
      this.business.MembershipType === BusinessBranchMembershipType.Statewide && this.statewideSelected)
    {
      return 50
    } else {
      return 0
    }
  }

  get totalValue (): number {
    let runningTotal = 0
    runningTotal += this.allAussieExtrasValue
    runningTotal += this.promoPackValue
    runningTotal += this.photographPricing
    runningTotal += this.websitePrice
    runningTotal += (this.additionalZones.length * 22)
    if (this.mainStateId)
    {
      const state = this.utils.getState(this.mainStateId.toString())
      runningTotal += state?.Price ?? 0
    }
    return runningTotal
  }

  get savings (): number {
    return this.totalValue - this.totalPrice
  }

  // COMPUTED HELPERS
  get localSelected (): boolean {
    return !(this.statewideSelected || this.websiteSelected || this.nationalSelected)
  }

  get renewalPrice (): number {
    return this.totalPrice / 2
  }

  get affirmationText (): string {
    if (
      this.photographSelected === null ||
      this.websiteSelected === null ||
      this.promoPackSelected === null ||
      this.nationalSelected === null
    ) { return 'Continue selecting package options to checkout' }
    if ((this.promoPackSelected && this.websiteSelected) || (this.nationalSelected && this.websiteSelected))
    {
      return 'CONGRATULATIONS!' + '\n' + 'YOU JUST RECEIVED OUR BEST DEAL EVER!!'
    }
    if (this.nationalSelected)
    {
      return 'CONGRATULATIONS! THIS IS A TERRIFIC SAVING ON BIZ ADVERTISING!!'
    }
    if (this.promoPackSelected)
    {
      return 'CONGRATULATIONS! THIS IS A TERRIFIC SAVING ON BIZ ADVERTISING!!'
    }
    return 'CONGRATULATIONS & WELCOME TO TRADING MATE!!'
  }

  private errorMessage = ''

  // INPUT HANDLERS
  onMainStateInput (stateId: string): void {
    if (!stateId) return
    this.mainStateId = stateId
    this.onStatewideClick()
  }

  onLocalClick (): void {
    this.errorMessage = ''
    // this.localSelected = true
    this.statewideSelected = false
    this.nationalSelected = false
    // this.additionalStates = []
    this.additionalZones = []
    // this.allAussieExtrasSelected = false
    this.promoPackSelected = false
  }

  onWebsiteSelected (): void {
    this.errorMessage = ''
    if (!(this.statewideSelected || this.nationalSelected))
    {
      this.onStatewideClick()
    }
    this.websiteSelected = true
  }

  stateSelected (stateId: string): void {
    this.errorMessage = ''
    const state = this.utils.getState(stateId)
    if (state) {
      this.mainStateId = stateId
      this.onStatewideClick()
    } else {
      this.statewideSelected = false
    }
  }

  onStatewideClick (): void {
    this.errorMessage = ''
    // don't change promo pack because you can be statewide and have promo pack
    // this.localSelected = false
    this.statewideSelected = true
    this.nationalSelected = false
    // this.nationalSelected = false
  }

  onPromoPackClick (): void {
    this.errorMessage = ''
    this.promoPackSelected = true
    this.onStatewideClick()
  }

  onNationalClick (): void {
    this.errorMessage = ''

    // Toggle
    if (this.nationalSelected === false)
    {
      this.statewideSelected = false
      this.mainStateId = ''
      this.promoPackSelected = false
      this.additionalZones = []
      this.nationalSelected = true
    }
    else
    {
      this.nationalSelected = false
    }
  }

  onGoToCheckout (): void {
    this.errorMessage = ''
    // Must have made a selection for every option
    console.log('go to checkout')

    if (
      this.websiteSelected === null ||
      this.photographSelected === null ||
      this.promoPackSelected === null ||
      this.nationalSelected === null
    ) {
      console.log('all options not selected')
      this.errorMessage = 'Must choose every option'
      // this.affirmationText = 'Please select yes or no to all options'
      return
    }

    // Must select a state in statewide
    if ((this.statewideSelected || this.websiteSelected || this.promoPackSelected) && this.mainStateId === '') {
      const stateEditor = this.$refs.stateEditor as StateEditor
      const modelState: Record<string, string[]> = { StateEditor: ['Must choose a state'] }
      stateEditor.httpError = { Status: 400, ModelState: modelState }
      console.log('cant be in a statewide package without having picking state')
      return
    }

    // Must have a valid domain if wants website
    if (this.websiteSelected && !this.websiteDomainValid) {
      const domainChecker = this.$refs.domainChecker as WebsiteDomainChecker
      const modelState: Record<string, string[]> = { Available: ['Must choose domain for website'] }
      domainChecker.httpError = { Status: 400, ModelState: modelState }
      console.log('If you want a website you must pass the domain check')
      return
    }

    // Selections are valid create checkout request to stripe
    this.loading = true

    const branchId: string = this.business.BusinessBranchId
    if (!branchId) return

    const request = this.getCheckoutRequest()
    if (!request) return

    console.log('request package: ' + request.SignupPackage.toString())

    Services.API.Payment.CheckoutRequest(branchId, request)
      .then((response) => {
        console.log('response')
        console.info(response)
        window.open(response.SessionUrl, '_self')
      })
      .catch((error) => {
        this.httpError = error
        console.log(error)
        console.error(error)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private payLaterLoading = false
  private payLaterResolved = false
  onPayLaterInvoice (): void {
    this.payLaterLoading = true
    this.payLaterResolved = false
    const branchId: string = this.business.BusinessBranchId
    if (!branchId) return
    const request = this.getCheckoutRequest()
    if (!request) return
    Services.API.Payment.PayLaterRequest(branchId, request)
      .then((response) => {
        this.payLaterResolved = response
      })
      .catch((errors) => {
        console.error(errors)
      })
      .finally(() => {
        this.payLaterLoading = false
      })
  }

  onWebsiteButtonSelected (): void {
    this.errorMessage = ''
    this.websiteSelected = true
  }

  onRemoveState (id: string): void {
    const index = this.additionalZones.findIndex((item) => item.ZoneId === id)
    if (index === -1) return
    this.additionalZones.splice(index, 1)
  }

  getCheckoutRequest (): SignupCheckoutRequest | void {
    console.log('Is express?')
    console.log(this.expressSelected)
    console.log('Is local?')
    console.log(this.localSelected)
    console.log('Is statewide?')
    console.log(this.statewideSelected)

    const request = new SignupCheckoutRequest()
    if (this.expressSelected) {
      request.ExpressSignup = true
    }

    if (this.additionalZones.length)
    {
      request.AdditionalZoneIds = this.additionalZones.map(x => x.ZoneId)
    }

    if (this.photographSelected) {
      request.WantsPhoto = true
    }

    if (this.localSelected) {
      request.SignupPackage = SignupPackage.B2b
      // cant have any more extras / options
      return request
    }

    if (this.statewideSelected) {
      request.SignupPackage = SignupPackage.Statewide
      request.MainStateId = this.mainStateId
    }

    if (this.websiteSelected) {
      request.SignupPackage = SignupPackage.Website
      request.WantsWebsite = true
      request.Domain = this.websiteDomain
      request.MainStateId = this.mainStateId
    }

    // Will keep website details and override package
    if (this.promoPackSelected) {
      request.SignupPackage = SignupPackage.PromoPack
      request.MainStateId = this.mainStateId
    }

    // will override website package but keep other info
    if (this.nationalSelected) {
      request.SignupPackage = SignupPackage.AllAussieExtras
    }

    return request
  }
}
